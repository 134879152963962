import { useEffect, useState } from "react";
import { getDocs, collection, deleteDoc, doc, orderBy, query } from "firebase/firestore";
import { db } from "../firebase-config";
import BlogCard from "../component/BlogCard";
import { Modal } from "antd";
import { Link } from "react-router-dom";

const Blog = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [postLists, setPostList] = useState([]);
  const { confirm } = Modal;

  const postsCollectionRef = query(
    collection(db, "posts"),
    orderBy("date", "desc")
  );
  
  const deletePost = (e) => {
 console.log(e)
  };



  useEffect(() => {
    const getPosts = async () => {
      const data = await getDocs(postsCollectionRef);
      setPostList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setIsLoading(false);
    };

    getPosts();
  }, [deletePost, postsCollectionRef]);

  return (
    <div className="w-full">
      {/* // Top Section */}
      <div className="bg-[#F2F1E8]">
        <div className=" flex w-full laptop:max-w-[1152px] mx-auto px-4 tablet:px-6 laptop:px-8 xl:px-0 py-[40px] tablet:py-[80px] laptop:py-[100px]">
          <p className="text-[64px] font-bold leading-[72px] tracking-[-2px] text-[#2E2E27] mx-auto w-fit">
            Blog
          </p>
        <Link to='/uploadblog' className="bg-[#21AB68] border-[#21AB68] shadow-navbarLink inline-flex items-center justify-center p-2 h-full text-[#ffffff]  text-sm leading-5 font-normal focus:outline-none ">Add Template</Link>

        </div>
      </div>

      <div className="bg-[#FFF]">
        <div className="w-full laptop:max-w-[1152px] mx-auto px-4 tablet:px-6 laptop:px-8 xl:px-0 my-[40px] tablet:my-[80px] laptop:my-[100px]">
          <div className="grid gap-[54px] tablet:grid-cols-2 tablet:gap-x-8 tablet:gap-y-10 laptop:grid-cols-3 laptop:gap-y-[50px]">
            {postLists &&
              postLists.map((post) => (
                <>
                  <BlogCard
                    key={post.title}
                    id={post.id}
                    date={post.date}
                    image={post?.image}
                    desc={post.title}
                    deleteSinglePost={deletePost}
                  />
                </>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
