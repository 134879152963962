import React, { useEffect, useState } from "react";
import Arrow from "../assets/back.svg";
import { useNavigate } from "react-router-dom";
import { store } from "../store";
import axios from "../lib/axios";
import Card from "../component/Dashboard/Card";
import DataTable from "../component/Table/DataTable";

export const Revenue = () => {

  const { data } = store();


  const navigate = useNavigate();

  const columns = ['Email Address','Company Name', 'Product name ', 'Website address', 'Format', 'Action']
  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page in history
  };
  return (
    <div className="bg-[#F2F1E8] min-h-screen">
        <div className="pt-10 w-full laptop:max-w-[1152px] mx-auto">
      <img
        src={Arrow}
        alt=""
        className="p-[6px] ml-6 desktop:ml-0  bg-[#ffffff] hover:bg-[#CCC8A4] w-6 h-6"
        onClick={handleGoBack}
      />
<p className="py-8 text-[#101828] text-48 font-bold">Emails for Revenue policy</p>
<div className="flex gap-5 mb-14">
<Card name="No of Markup generated" value={'0'} />
<Card name="No of plain text generated" value={'0'} />
<Card name="No of HTML generated" value={'0'} />
</div>
      <div className="overflow-x-auto text-[#101828] border border-[#EAECF0] bg-white-1 shadow-table-shadow">
        <p className="text-[18px] leading-[28px] font-medium px-6 py-5">
        Revenue policy Users
        </p>
        <DataTable data={data.listOfRefundPolicyEmailAddress} columns={columns} pageSize={10} />
      </div>
    </div>
    </div>
    
  );
};



