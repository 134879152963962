import Cancel from "../assets/cancel.svg"



const PopUpData = ({setShowData, showData, data}) => {



 const close = ()=>{
    setShowData(false)
}
  return (
    showData &&
    <div className='w-[100vw] h-[100vh] flex items-center justify-center bg-overlay fixed top-0 left-0 z-20'>
      <div className="w-[90%] max-w-[499px] flex flex-col gap-6 p-6 rounded-[12px] bg-white-1 border-[rgb(232,232,234)] border">
        <img src={Cancel} alt="" className="mr-0 ml-auto w-6 h-6" onClick={close}/>
        <div className="flex flex-col gap-10">
        <p className="text-20 text-left font-bold tablet:text-24">{data.contactEmailAddress}</p>
    <div>
<div className="flex justify-between py-4 border-b-[#D5DDDC]-1">
<p className="text-16 text-left font-normal">Email address</p>
<p className="text-16 text-right font-medium">{data.contactEmailAddress}</p>
</div>
<div className="flex justify-between py-4 border-b-[#D5DDDC]-1">
<p className="text-16 text-left font-normal">Company name</p>
<p className="text-16 text-right font-medium">{data.companyName}</p>
</div>
<div className="flex justify-between py-4 border-b-[#D5DDDC]-1">
<p className="text-16 text-left font-normal">Product name</p>
<p className="text-16 text-right font-medium">{data.productName}</p>
</div>
<div className="flex justify-between py-4 border-b-[#D5DDDC]-1">
<p className="text-16 text-left font-normal">Website address</p>
<p className="text-16 text-right font-medium">{data.websiteAddress}</p>
</div>
<div className="flex justify-between py-4 border-b-[#D5DDDC]-1">
<p className="text-16 text-left font-normal">generate</p>
<p className="text-16 text-right font-medium">{data.format}</p>
</div>
<div className="flex justify-between py-4 border-b-[#D5DDDC]-1">
<p className="text-16 text-left font-normal">Country</p>
<p className="text-16 text-right font-medium">{data.country}</p>
</div>
<div className="flex justify-between py-4 border-b-[#D5DDDC]-1">
<p className="text-16 text-left font-normal">City</p>
<p className="text-16 text-right font-medium">{data.city}</p>
</div>
<div className="flex justify-between py-4 border-b-[#D5DDDC]-1">
<p className="text-16 text-left font-normal">Address</p>
<p className="text-16 text-right font-medium">{data.companyAddress}</p>
</div>
    </div>
        <p className="text-[#0A0A0A] cursor-pointer bg-white border border-[#D2D2CF] hover:bg-[#F9F9F9] shadow-navbarLink inline-flex items-center justify-center py-3 px-6 text-sm leading-5 font-semibold focus:outline-none" onClick={close}>Close</p>
        </div>


      </div>
    </div>
  )
}

export default PopUpData