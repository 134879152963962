import React, { useState, useRef, useEffect } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { store } from "../store";
import { storage } from '../firebase-config';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Loading from "../component/LoadingComponent";
import { Notification } from "../component/Notification";
import axios from "axios";

function SendMail({ isAuth }) {
  const [formData, setFormData] = useState({
    "subject": "",
    "content": ""
  });

  const [mounted, setMounted] = useState(false); // New state to check if component is mounted
  const quillRef = useRef(null);
  const { token, setIsComponentLoading } = store();

  // Ensure the component is fully mounted before rendering the editor
  // useEffect(() => {
  //   setMounted(true);  // Set to true after component mounts
  //   console.log("Unmounted again")
  // }, []);

  useEffect(() => {
    let hasMounted = false; // Local flag to ensure it runs only once
    if (quillRef.current) {
      quillRef.current.focus();
    }
    if (!hasMounted) {
      setMounted(true);  // Set to true after component mounts
      hasMounted = true;
    }

    return () => {
      hasMounted = false;  // Cleanup to prevent any potential issues
    };
  }, []);

  const handlePublish = async () => {
    try {
      setIsComponentLoading(true);
      await axios.post(`https://sendmail-testing.onrender.com/api/sendEmails`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      });
      Notification("Email sent Successfully");
    } catch (error) {
      Notification("Email not sent");
    } finally {
      setIsComponentLoading(false);
    }
  };

  const handleBody = (content) => {
    setFormData({ ...formData, content });
  };

  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (!file || !file.type.startsWith('image/')) {
        Notification("Please select a valid image file");
        return;
      }

      const storageRef = ref(storage, `images/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          Notification("Image upload failed");
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          const editor = quillRef.current.getEditor();
          const cursorPosition = editor.getSelection()?.index || 0;
          editor.insertEmbed(cursorPosition, 'image', downloadURL);
          editor.setSelection(cursorPosition + 1);  // Move cursor after the image
          Notification("Image uploaded successfully");
        }
      );
    };
  };

  SendMail.modules = {
    toolbar: {
      container: [
        [{ 'font': [] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'header': 1 }, { 'header': 2 }, { header: [3, 4, 5, 6] }],
        ['blockquote', 'code-block', 'code'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'align': [] }],
        [{ 'direction': 'ltr' }],
        ['link', 'image', 'video'],
        ['clean']
      ],
      handlers: {
        image: imageHandler
      }
    }
  };

  SendMail.formats = [
    "header", "font", "size", "bold", "italic", "underline", "strike",
    "blockquote", "list", "bullet", "link", "image", "video", "code-block",
    'indent', "color", 'direction', 'clean', 'background', 'script', 'code',
    'align'
  ];

  return (
    <div className="BlogPage">
      <div className="bg-[#FFFFFF]">
        <div className="w-full laptop:max-w-[1152px] mx-auto px-4 py-[40px]">
          <p className="text-[64px] font-bold text-[#2E2E27] mx-auto w-fit">
            Send Emails
          </p>
        </div>
      </div>

      <div className="w-full laptop:max-w-[1152px] mx-auto px-4 py-[40px]">
        <div className="w-full mb-4">
          <input
            name="subject"
            placeholder="Subject..."
            value={formData.subject}
            onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
            className="w-full px-4 py-2 border rounded-md"
          />
        </div>

        {mounted && (
          <ReactQuill
            // ref={quillRef}
            placeholder="Write the email content..."
            modules={SendMail.modules}
            formats={SendMail.formats}
            onChange={handleBody}
            value={formData.content}
          />
        )}

        <p className="border border-[#21AB68] bg-[#21AB68] p-4 text-[#fff] mt-10 w-fit cursor-pointer" onClick={handlePublish}>
          Send Email <Loading />
        </p>
      </div>
    </div>
  );
}

export default SendMail;
