import { Select } from 'antd';

const Deliverables = ({value, selected}) => {
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tags = [
    {
      value: "figma",
      label: "figma",
    },
    {
      value: "canva",
      label: "canva",
    },
    {
      value: "presentation",
      label: "presentation",
    },
  ];

  const handleChange = (selected) => {
    value(selected)
  };

  return(
    <Select
    mode="multiple"
      defaultValue={selected ? selected : tags[0]?.label}
      placeholder='Deliverables'
      onChange={handleChange}
      options={tags}
    />
  );
  }
export default Deliverables;