import Arrow from "../assets/back.svg";
import { useNavigate } from "react-router-dom";
import { store } from "../store";
import Table from "../component/Table/ActiveUsersTable";
import { useEffect, useState } from "react";
import axios from "../lib/axios";

export const ActiveUsers = () => {

  const { token } = store();
  const navigate = useNavigate();

  const [users, setUsers] = useState([])
  const [totalUsers, setTotalUsers] = useState();

  // const columns = ['Email', 'First Name', 'Last Name']
  const columns = ['Registered', 'Last Login', 'Username', 'Full Name', 'Email', 'Source', 'Verified', 'Country']
  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page in history
  };

  useEffect(() => {
     axios.get(`/metrics/statistics/active-users`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then(function (response) {
            setUsers(response.data.activeUsers)
     setTotalUsers(response.data.totalNumberOfActiveUsers)
  
          })
    // Your logic to handle state change based on the selected option
       
  }, [token]); // Dependency array ensures the effect runs only when selectedOption changes
  
  
  return (
    <div className="bg-[#F2F1E8] min-h-screen">
        <div className="pt-10 w-full laptop:max-w-[1152px] mx-auto">
      <img
        src={Arrow}
        alt=""
        className="p-[6px] ml-6 desktop:ml-0 mb-8 bg-[#ffffff] hover:bg-[#CCC8A4] w-6 h-6"
        onClick={handleGoBack}
      />

      <div className="overflow-x-auto text-[#101828] border border-[#EAECF0] bg-white-1 shadow-table-shadow">
      <div className="flex justify-between">
        <p className="text-[18px] leading-[28px] font-medium px-6 py-5">
          Active users
        </p>
        <p className="text-16 desktop:text-18 font-medium px-6 py-5">Total users: {totalUsers}</p>
        </div>
        <Table data={users} columns={columns} pageSize={10} />
      </div>
    </div>
    </div>
    
  );
};



