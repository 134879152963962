
import PitchContent from "../sections/PitchContent";
import Hero from "../sections/PitchHero";

const Pitchdeck = () => {

return (
  <div className='w-full bg-[#F2F1E8]' id='pricing'>
    <div className="">
        {/* <Hero /> */}
       <PitchContent />
      </div>
      </div>
  );
};

export default Pitchdeck;
