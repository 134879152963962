import Hero from "../sections/TemplateHero";
import TemplateContent from "../sections/TemplateContent";
import { Link } from "react-router-dom";


const Templates = () => {



  return (
    <>
          <div className="bg-[#F2F1E8]">
        {/* <Hero /> */}
        {/* <div className='flex flex-wrap justify-center gap-x-2 gap-y-[10px] mb-6 pt-10 desktop:mb-[50px] desktop:gap-x-6'>
        <h1 className='text-32 font-bold tablet:text-64 text-[#2E2E27]'>Your Templates</h1>
        <Link to='/uploadtemplate' className="bg-[#21AB68] border-[#21AB68] shadow-navbarLink inline-flex items-center justify-center p-2 h-full text-[#ffffff]  text-sm leading-5 font-normal focus:outline-none ">Add Template</Link>
        </div> */}
        <div className=" flex w-full laptop:max-w-[1152px] mx-auto px-4 tablet:px-6 laptop:px-8 xl:px-0 py-[40px] tablet:py-[80px] laptop:py-[100px]">
          <p className="text-[64px] font-bold leading-[72px] tracking-[-2px] text-[#2E2E27] mx-auto w-fit">
          Your Templates
          </p>
        <Link to='/uploadtemplate' className="bg-[#21AB68] border-[#21AB68] shadow-navbarLink inline-flex items-center justify-center p-2 h-full text-[#ffffff]  text-sm leading-5 font-normal focus:outline-none ">Add Template</Link>
        </div>
       <TemplateContent/>
      </div>
    </>
  );
};

export default Templates;
