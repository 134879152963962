import Arrow from "../assets/back.svg";
import { useNavigate } from "react-router-dom";
import { store } from "../store";
import Table from "../component/Table/UsersTable";
import { useEffect, useState } from "react";
import axios from "../lib/axios";

export const Users = () => {

  const [users, setUsers] = useState([])
  const [selectedOption, setSelectedOption] = useState("all");
  const [totalUsers, setTotalUsers] = useState();

  const { data, token } = store();

  const navigate = useNavigate();

  const columns = ['Date', 'Username', 'Full Name', 'Email', 'Source', 'Verified', 'Country']
  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page in history
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
};

useEffect(() => {
  // eslint-disable-next-line no-unused-expressions
  selectedOption === 'all' ? <>
    {setUsers(data.listOfRegisteredUsers)}
  {setTotalUsers(data.totalNumberOfUsers)}
  </>
  
   : 

   selectedOption === 'active' ?
   axios.get(`/metrics/statistics/active-users`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          setUsers(response.data.activeUsers)
   setTotalUsers(response.data.totalNumberOfActiveUsers)

        })
  // Your logic to handle state change based on the selected option
      :
      axios.get(`/metrics/statistics/users?period=${selectedOption}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          setUsers(response.data.users)
   setTotalUsers(response.data.NumberOfRegisteredUsers)

        });
    
  // console.log("Selected option changed:", selectedOption);
  // Example logic:
  // if (selectedOption === "today") {
  //     // Do something
  // } else if (selectedOption === "week") {
  //     // Do something else
  // }
}, [data.listOfRegisteredUsers, selectedOption]); // Dependency array ensures the effect runs only when selectedOption changes



  return (
    <div className="bg-[#F2F1E8] min-h-screen">
        <div className="pt-10 w-full laptop:max-w-fit mx-auto">
          <div className="flex justify-between px-6">
           <img
        src={Arrow}
        alt=""
        className="p-[6px] mb-8 bg-[#ffffff] hover:bg-[#CCC8A4] w-6 h-6"
        onClick={handleGoBack}
      /> 
      <div className='border border-[#2D2D2D] W-[275px] h-fit'>
      <select name="filter" className='w-full' value={selectedOption} onChange={handleSelectChange}>
                <option value="all">All</option>
                <option value="active">Active users</option>
                <option value="daily">Today</option>
                <option value="weekly">A week</option>
                <option value="monthly">A month</option>
                <option value="yearly">A year</option>
            </select>
</div>
          </div>
      

      <div className="overflow-x-auto text-[#101828] border border-[#EAECF0] bg-white-1 shadow-table-shadow">
        <div className="flex justify-between">
        <p className="text-16 desktop:text-18 font-medium px-6 py-5">
          {selectedOption === "daily" ? "Today" : selectedOption === "weekly" ? "This Week" : selectedOption === "monthly" ? "This Month" : selectedOption === "yearly" ? "This Year" : selectedOption === "active" ? "Active Users" : "All"} users
        </p>
        <p className="text-16 desktop:text-18 font-medium px-6 py-5">Total users: {totalUsers}</p>
        </div>
        <Table data={users} columns={columns} pageSize={10} />
      </div>
    </div>
    </div>
    
  );
};



