import Card from "./Card";
import {useEffect} from "react"
// import { emailUsers, googleUsers, totalPitches, totalTemplates, totalUsers } from '../../lib/data'
import { store } from "../../store";

const Cards = () => {
  const { data, fetchData, token } = store();


  useEffect(()=>{
    fetchData(token)
},[token])
  return (
    <div className="tablet:grid tablet:justify-between gap-8 tablet:grid-cols-2 laptop:grid-cols-3 desktop:grid-cols-4">
      <Card name="No of Users" value={data.totalNumberOfUsers} link='/users'/>
      <Card name="No of Email Users" value={data.totalNumberOfEmailUsers} />
      <Card name="No of Google Users" value={data.totalNumberOfGoogleUsers} />
      <Card name="Revenue" value={data.totalPitches} />
      <Card name="No of Pitchdecks" value={data.totalNumberOfPitchdecks} />
      <Card name="No of Templates" value={data.totalNumberOfTemplates} />
      <Card name="No of Templates Sold" value={data.totalPitches} />
      <Card name="Active Users" value={data.totalNumberOfActiveUsers}  link='/active-users'/>
      {/* <Card name="No of T&C generated" value={data.totalNumberOfTermsAndConditionsGenerated} /> */}
      <Card name="Emails for T&C" value={data.listOfTermsAndCondsEmailAddress.length} link='/terms'/>
      <Card name="Emails for Revenue policy generated" value={data.totalNumberOfRefundPolicyGenerated}  link='/revenue'/>
      <Card name="Emails for Privacy policy" value={data.totalNumberOfPrivacyPolicyGenerated} link='/policy'/>
      {/* <Card name="No of Revenue policy generated" value={data.totalPitches} /> */}
      {/* <Card name="Emails for Revenue policy" value={data.totalPitches} link='/revenue'/> */}
    </div>
  );
};

export default Cards;
