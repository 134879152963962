import React, { useState, useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { Button, Modal } from "antd";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase-config";
import { store } from "../store";
import { LoadingOverlay } from "./LoadingOverlay";

const ImageUpload = ({ title, contentImages, contentPath, updatedImagesUrl }) => {
  const [images, setImages] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [uploadingIndexes, setUploadingIndexes] = useState([]);

  const { setIsOverlayLoading } = store();


  const onDrop = (acceptedFiles) => {
    setImages((prevImages) => [...prevImages, ...acceptedFiles]);
  };

  const uploadImage = async (image, index) => {
    setIsOverlayLoading(true)
    setUploadingIndexes((prevIndexes) => [...prevIndexes, index]);
    try {
      const storageRef = ref(storage, `/${contentPath}/${Date.now()}${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.error("Error uploading image:", error);
        },
        async () => {
          const imageUrl = await getDownloadURL(uploadTask.snapshot.ref);
          setImages((prevImages) => [...prevImages, imageUrl]);
          updatedImagesUrl((prevImages) => [...prevImages, imageUrl]);
          console.log(imageUrl)
          setIsOverlayLoading(false)
          setUploadingIndexes((prevIndexes) => prevIndexes.filter((idx) => idx !== index));

        }
      );
    } catch (error) {
      console.error("Error uploading image:", error);
      setIsOverlayLoading(false)
      setUploadingIndexes((prevIndexes) => prevIndexes.filter((idx) => idx !== index));

    }
  };

  useMemo(() => {
    if (!contentImages.length) return; // If contentImages is empty, do nothing
  
    // Check if images state is already set
    if (!images.length) {
      // If images state is empty, set it to contentImages
      setImages(contentImages);
    } else {
      // Otherwise, upload only the images that are not yet uploaded
      contentImages.forEach((image, index) => {
        if (!images[index]) {
          uploadImage(image)
            .then((imageUrl) => {
              setImages((prevImages) => [...prevImages.slice(0, index), imageUrl, ...prevImages.slice(index + 1)]);
            })
            .catch((error) => {
              console.error("Error uploading image:", error);
            });
        }
      });
    }
  }, [contentImages]);
  

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  const deleteImage = (imageUrl) => {
    try {
      // Filter out the image from the images state
      const updatedImages = images.filter((image) => image !== imageUrl);
      setImages(updatedImages);
      updatedImagesUrl(updatedImages);
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  const handleViewImage = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setModalVisible(true);
  };

  const handleUpdateImage = async (index) => {
    try {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = "image/*";
      fileInput.click();

      fileInput.addEventListener("change", async () => {
        setIsOverlayLoading(true)
        setUploadingIndexes((prevIndexes) => [...prevIndexes, index]);

        const file = fileInput.files[0];
        if (file) {
          const storageRef = ref(storage, `/${contentPath}/${Date.now()}${file.name}`);
          const uploadTask = uploadBytesResumable(storageRef, file);


          uploadTask.on(
            "state_changed",
            null,
            (error) => {
              console.error("Error uploading image:", error);
            },
            async () => {
              const imageUrl = await getDownloadURL(uploadTask.snapshot.ref);
              const updatedContentImages = [...images];
              // const updatedContentImages = [...contentImages];
              updatedContentImages[index] = imageUrl;
              updatedImagesUrl(updatedContentImages);
              setImages(updatedContentImages)
              setIsOverlayLoading(false)
              setUploadingIndexes((prevIndexes) => prevIndexes.filter((idx) => idx !== index));

            }
          );
        }
      });
    } catch (error) {
      console.error("Error updating image:", error);
      setIsOverlayLoading(false)
      setUploadingIndexes((prevIndexes) => prevIndexes.filter((idx) => idx !== index));

    }
  };

  return (
    <div className="py-10">
      <h1 className="mb-4">{title}</h1>

      <div className="grid grid-cols-2 justify-between gap-x-8 gap-y-5 mb-5">
        {images.map((imageUrl, index) => (
          <div key={index} className="relative w-fit">
            <img
              src={imageUrl}
              alt={`Uploaded ${index + 1}`}
              className="bg-overlay overflow-hidden transition-transform duration-75 ease-linear"
              style={{ width: "264px", height: "174px" }}
            />
            {uploadingIndexes.includes(index) && <LoadingOverlay />}

            <div className="flex justify-center items-center gap-[5px] absolute h-full w-full top-0 bg-overlay">
              <button
                className="bg-white-1 px-4 py-2 text-14 text-[#3E7B52] font-medium hover:bg-slate-300"
                onClick={() => handleViewImage(imageUrl)}
              >
                View
              </button>
              <button
                className="border border-[#FF6464] bg-[#FF6464] px-4 py-2 text-14 text-white font-medium hover:bg-slate-300"
                onClick={() => deleteImage(imageUrl)}
              >
                Delete
              </button>
              <button
                className="bg-[#3E7B52] text-white font-medium px-4 py-2 text-14 hover:bg-slate-300"
                onClick={() => handleUpdateImage(index)}
              >
                Update
              </button>
            </div>
          </div>
        ))}
      </div>

      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <Button className="text-[#3E7B52] bg-[#A9EFCD] text-14 font-medium px-4 py-2 rounded-none">
          Add new image
        </Button>
      </div>

      {modalVisible && (
        <Modal
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          footer={null}
          width={800}
        >
          <img src={selectedImageUrl} alt="Selected" style={{ width: "100%" }} />
        </Modal>
      )}
    </div>
  );
};

export default ImageUpload;
