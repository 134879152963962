import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Input, Button } from "antd";
import { store } from "../store";
import axios from "../lib/axios";
import Icon from "./../assets/Upload icons.svg"
import Deliverables from "../component/Form/Deliverables";
import ImageCover from "../component/UploadCover";
import { Notification } from "../component/Notification";
import ImageUpload from "../component/UpdateContentImages";
import Loading from "../component/LoadingComponent";

function EditTemplate({ isAuth }) {
  const { fetchSingleTemplate, template, token, setIsComponentLoading } = store();

  const [formData, setFormData] = useState(template);
  const [images, setImages] = useState([]);

  const params = useParams();

  useEffect(() => {
    const currentPathname = window.location.pathname;
    const path = currentPathname.split("/").pop();
    const templateTitle = params.template !== "" ? params.template : path;

    fetchSingleTemplate(templateTitle);

  }, [fetchSingleTemplate, params.template]);

  useEffect(() => {
    setImages(formData.templateImagesUrl)
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const getImage = (res) => {
    setFormData((prevData) => ({ ...prevData, templateCoverImageUrl: res }));
  };

  const handlePublish = async () => {
    try {
      setIsComponentLoading(true);
      await axios.patch(`/templates/update/${template._id}`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Credentials': true
        },
      });
      setIsComponentLoading(false);
      console.log(formData)
      Notification("Template Updated Successfully");
    } catch (error) {
      setIsComponentLoading(false);
      Notification("Error Updating Template");
      console.error("Error updating template:", error);
    }
  };

  const uploadedImage = (images) => {
    setImages(images)
    setFormData((prevData) => ({ ...prevData, templateImagesUrl: images }));
  }

  const handleAbout = (e) => {
    setFormData((prevData) => ({ ...prevData, about: e.target.value }));
  };

  const handleHighlight = (e) => {
    setFormData((prevData) => ({ ...prevData, pageHighlights: e.target.value }));
  };

  const deliverables = (e) => {
    setFormData((prevData) => ({ ...prevData, deliverables: e }));
  };

  return (
    <div className="BlogPage bg-[#F2F1E8]">
      <div className="">
        <div className="w-full flex justify-between items-center laptop:max-w-[1152px] mx-auto px-4 tablet:px-6 laptop:px-8 xl:px-0 py-[40px] tablet:pt-[80px] laptop:pt-[100px]">
          <p className="text-[64px] font-bold leading-[72px] tracking-[-2px] text-[#2E2E27] mx-auto w-full">
            Update Template</p>
          <p className="border border-[#21AB68] bg-[#21AB68] p-4 text-16 text-[#FFFFFF] hover:bg-[#21AB78] w-fit whitespace-nowrap flex items-center gap-2 cursor-pointer" onClick={handlePublish}> Save and upload template <Loading />
          </p>
        </div>
      </div>

      <div className="w-[90%] max-w-[559px] flex flex-col gap-4 tablet:gap-6 laptop:gap-8  mx-auto">
        <div className="relative w-full flex bg-white h-[342px] bg-white-1 border border-dashed border-[#D2D2CF]">
          <div className="w-full flex flex-col gap-4 bg-white h-[342px] items-center justify-center border border-dashed border-[#D2D2CF]">
            <ImageCover coverImage={getImage} uploaded={formData.templateCoverImageUrl} coverPath='templateCoverImages' />
          </div>
        </div>

        <div className="w-full flex flex-col gap-4">
          <p className="pt-6 font-16 font-medium text-[#2E2E27]">Template Name</p>
          <Input
            name="name"
            placeholder="Enter Template Name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-[#C1C9C8]"
          />
        </div>
        <div className="w-full flex flex-col gap-4">
          <p className="pt-6 font-16 font-medium text-[#2E2E27]">About Template</p>
          <Input.TextArea
            name="about"
            placeholder="About this template"
            value={formData.about}
            onChange={handleAbout}
            className="w-full h-[121px] px-4 py-6 border border-[#C1C9C8]"
          />
        </div>

        <div className="w-full flex flex-col gap-4">
          <p className="pt-6 font-16 font-medium text-[#2E2E27]">Template Highlights </p>
          <Input.TextArea
            name="pageHighlights"
            placeholder="Describe Pitch deck"
            value={formData.pageHighlights}
            onChange={handleHighlight}
            className="w-full h-[121px] px-4 py-6 border border-[#C1C9C8]"
          />
        </div>

        <div className="w-full flex flex-col gap-4">
          <p className="pt-6 font-16 font-medium text-[#2E2E27]">Link to Purchase (Naira)</p>
          <Input
            name="nigeriaLinkToPurchase"
            placeholder="www.pitchdeck.design/link"
            value={formData.linkToPurchase?.nigeria}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-[#C1C9C8]"
          />
        </div>

        <div className="w-full flex flex-col gap-4">
          <p className="pt-6 font-16 font-medium text-[#2E2E27]">Template cost (Naira)</p>
          <Input
            name="nairaAmount"
            placeholder="Amount"
            value={formData.cost?.naira}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-[#C1C9C8]"
          />
        </div>

        <div className="w-full flex flex-col gap-4">
          <p className="pt-6 font-16 font-medium text-[#2E2E27]">Link to Purchase (Dollar)</p>
          <Input
            name="foreignLinkToPurchase"
            placeholder="www.pitchdeck.design/link"
            value={formData.linkToPurchase?.foreign}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-[#C1C9C8]"
          />
        </div>

        <div className="w-full flex flex-col gap-4">
          <p className="pt-6 font-16 font-medium text-[#2E2E27]">Template cost (Dollar)</p>
          <Input
            name="dollarAmount"
            placeholder="Amount"
            value={formData.cost?.dollar}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-[#C1C9C8]"
          />
        </div>
        {/* Number of pages */}
        <div className="w-full flex flex-col gap-4">
          <label className="pt-6 font-16 font-medium text-[#2E2E27]">Number of pages</label>
          <Input
            name="numberOfPages"
            placeholder="5"
            value={formData.numberOfPages}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-[#C1C9C8]"
          />
        </div>

        {/* Deliverables */}
        <Deliverables selected={formData.deliverables} value={deliverables} />

        {/* Upload template images */}
        <ImageUpload contentImages={images} contentPath='templateImages' updatedImagesUrl={uploadedImage} title="Template images" />
      </div>
    </div>
  )
}

export default EditTemplate;
