import { store } from '../store'
import { useEffect, useMemo } from 'react'
import { useQuery } from 'react-query';
import axios from '../lib/axios'
import TemplateCard from '../component/template/TemplateCard'
import Loading from '../component/LoadingComponent'

const loadTemplates = async () => {
  const response = await axios.get('/templates/getTemplates');
  return response.data;;
};

const TemplateContent = () => {

  const { templates, fetchTemplates, setIsComponentLoading, componentLoading } = store()


  
  // Fetch All Templates
  const { data: loadedTemplates, isLoading } = useQuery('templates', loadTemplates);


  useEffect(() => {
    setIsComponentLoading(isLoading);

  

    fetchTemplates(loadedTemplates);


  }, [componentLoading, fetchTemplates, isLoading, loadedTemplates,  setIsComponentLoading]);

  return (
    <div className='w-full bg-[#F2F1E8]'>

      <div className='w-full laptop:max-w-[1152px] mx-auto px-4 tablet:px-6 laptop:px-8 desktop:px-0 pt-[25px] pb-[40px] tablet:pb-[80px] laptop:pb-[100px]'>
        <div className='relative grid tablet:grid-cols-2 laptop:grid-cols-3 gap-6 tablet:gap-[30px] laptop:flex-row laptop:gap-[30px] '>
          <Loading />
          {templates?.map(item => (
            <TemplateCard
              key={item._id}
              template={item}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default TemplateContent