import { useState } from "react";
import '../../node_modules/react-quill/dist/quill.snow.css'

import { Input } from "antd";
import ImageUpload from "../component/UploadImages";

import { store } from "../store";
import axios from "../lib/axios";
import UploadPdf from "../component/UploadPdf";
import SelectTag from "../component/Form/Tags";
import ImageCover from "../component/UploadCover";
import Loading from "../component/LoadingComponent";
import { Notification } from "../component/Notification";

function UploadPitch({ isAuth }) {

  const { TextArea } = Input;

  const { token, setIsComponentLoading } = store();

  const [formData, setFormData] = useState({
      title: "",
      tag: "",
      about: "",
      coverImageUrl: '',
      contentImagesUrls: [],
      pdfFileUrl: "",
      amountRaised: "",
    });

  const handlePdf = (res) => {
    setFormData({ ...formData, pdfFileUrl: res });
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const getImage = (res) => {
    setFormData({ ...formData, coverImageUrl: res });
  }

  const handleTags = (res) => {
    setFormData({ ...formData, tag: res });
  }

  const handlePublish = async () => {
    console.log(formData)
    try {
      setIsComponentLoading(true)
      await axios.post('/pitch/admin/create', formData, {
        headers: {
          'Content-Type': "application/json",
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Credentials': true
        },
      })
        // Update the state with the fetched data
        .then(function (response) {
          setFormData({
            title: "",
            tag: "",
            about: "",
            coverImageUrl: '',
            contentImagesUrls: [],
            pdfFileUrl: "",
            amountRaised: "",
          })
          setIsComponentLoading(false)
          Notification("Pitch Uploaded Successfully")

        });
    } catch (error) {
      setIsComponentLoading(false)
      Notification("Error Uploading Pitch")

      console.error("Error fetching data:", error);
    }
    setIsComponentLoading(false)

  };

  const uploadedImage = (images) => {
    setFormData({ ...formData, contentImagesUrls: images })
  }

  const handleBody = e => {
    setFormData({ ...formData, about: e.target.value });
  }

  return (
    <div className="BlogPage bg-[#F2F1E8]">
      <div className="">
        <div className="w-full flex justify-between items-center laptop:max-w-[1152px] mx-auto px-4 tablet:px-6 laptop:px-8 xl:px-0 py-[40px] tablet:pt-[80px] laptop:pt-[100px]">
          <p className="text-[64px] font-bold leading-[72px] tracking-[-2px] text-[#2E2E27] mx-auto w-full">
            Upload pitchdeck</p>
          <p className="border border-[#21AB68] bg-[#21AB68] p-4 text-16 text-[#FFFFFF] hover:bg-[#21AB78] w-fit whitespace-nowrap flex items-center gap-2 cursor-pointer" onClick={handlePublish}> Save and upload deck <Loading />
          </p>
        </div>
      </div>

      <div className="w-[90%] max-w-[559px] flex flex-col gap-4 tablet:gap-6 laptop:gap-8  mx-auto">
        <div className="w-full flex flex-col gap-4 bg-white h-[342px] items-center justify-center border border-dashed border-[#D2D2CF]">
          <ImageCover coverImage={getImage} coverPath='coverImages' />
        </div>

        <div className="w-full flex flex-col gap-3">
          <p className="font-16 font-medium text-[#2E2E27]"> Enter pitchdeck name </p>
          <input
            name="title"
            placeholder="Title..."
            value={formData.title}
            onChange={(e) => handleChange(e)}
            className="w-full px-4 py-2 border border-[#C1C9C8]"
          />
        </div>

        <div className="w-full flex flex-col gap-3">
          <p className="font-16 font-medium text-[#2E2E27]">Amount Raised</p>
          <input
            name="amountRaised"
            placeholder="Amount Raised"
            value={formData.amountRaised}
            onChange={(e) => handleChange(e)}
            className="w-full px-4 py-2 border border-[#C1C9C8]"
          />
        </div>

        <div className="w-full flex flex-col gap-3">
          <p className="font-16 font-medium text-[#2E2E27]"> Enter Pitchdeck Category </p>
        <SelectTag value={handleTags} />
        </div>

        <div className="w-full flex flex-col gap-3">
          <p className="font-16 font-medium text-[#2E2E27]"> About this pitchdeck </p>
          {/* <label htmlFor="about" className="font-16 font-medium text-[#2E2E27]">About this pitchdeck</label> */}
          <TextArea
            showCount
            // maxLength={100}
            onChange={handleBody}
            placeholder="Describe Pitch deck"
            style={{ height: 120, resize: 'none', padding: 8, border: '#C1C9C8' }}
            className="w-full h-[121px] px-4 py-6 border border-[#C1C9C8]"
          />
        </div>

        <UploadPdf pdfUploaded={handlePdf} />
        <ImageUpload contentImages={uploadedImage} contentPath='contentImages' title="Add deck images" />

      </div>
    </div>
  );
}



export default UploadPitch;
