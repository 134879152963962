import React, { useEffect, useMemo, useState } from "react";

import '../../node_modules/react-quill/dist/quill.snow.css'

import { Upload, Button, Input } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { store } from "../store";
import axios from "../lib/axios";
// import { createSlug } from "../component/slug";
// import Icon from "./../assets/Upload icons.svg"
import ImageCover from "../component/UploadCover";
import { Notification } from "../component/Notification";
import SelectTag from "../component/Form/Tags";
import ImageUpload from "../component/UpdateContentImages";
import UploadPdf from "../component/UploadPdf";
import Loading from "../component/LoadingComponent";



function EditPitch({ isAuth }) {
  const { token, fetchSinglePitch, pitch, setIsComponentLoading } = store()

  const [formData, setFormData] = useState(pitch);
  const [images, setImages] = useState([]);

  let params = useParams()


  const { TextArea } = Input;

  useEffect(() => {
    const currentPathname = window.location.pathname;
    const path = currentPathname.split("/").pop();
    const pitchTitle = params.pitch !== "" ? params.pitch : path;

    fetchSinglePitch(pitchTitle);

  }, [fetchSinglePitch, params.pitch]);


  useEffect(() => {
    setImages(formData.contentImagesUrls)
  }, [])

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePdf = (res) => {
    console.log(res)
    setFormData({ ...formData, pdfFileUrl: res });
  }

  const handleTags = (res) => {
    setFormData({ ...formData, tag: res });
  }

  const getImage = (res) => {
    setFormData({ ...formData, coverImage: res });
  };

  const handlePublish = async () => {
    console.log(formData)
    try {
      setIsComponentLoading(true)
      await axios.patch(`/pitch/admin/update/${pitch._id}`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Credentials': true
        }
      })
        .then(function (response) {
          setIsComponentLoading(false)
          Notification("Pitch deck Updated Successfully")
          console.log(response)
        });
    } catch (error) {
      setIsComponentLoading(false)
      Notification("Error Updating Pitch Deck")

      console.error("Error fetching data:", error);
    }
    setIsComponentLoading(false)
    Notification("Pitch Deck Not Updated")
  };

  const uploadedImage = (images) => {
    console.log(images)
    setImages(images)
    setFormData({ ...formData, contentImages: images })
  }

  const handleBody = e => {
    setFormData({ ...formData, about: e.target.value });
  }

  return (
    <div className="BlogPage bg-[#F2F1E8]">
      <div className="">
        <div className="w-full flex justify-between items-center laptop:max-w-[1152px] mx-auto px-4 tablet:px-6 laptop:px-8 xl:px-0 py-[40px] tablet:pt-[80px] laptop:pt-[100px]">
          <p className="text-[64px] font-bold leading-[72px] tracking-[-2px] text-[#2E2E27] mx-auto w-full">
            Update pitchdeck</p>
          <p className="border border-[#21AB68] bg-[#21AB68] p-4 text-16 text-[#FFFFFF] hover:bg-[#21AB78] w-fit whitespace-nowrap flex items-center gap-2 cursor-pointer" onClick={handlePublish}> Save and upload deck <Loading />
          </p>
        </div>
      </div>

      <div className="w-[90%] max-w-[559px] flex flex-col gap-4 tablet:gap-6 laptop:gap-8  mx-auto">
        <div className="w-full flex flex-col gap-4 bg-white h-[342px] items-center justify-center border border-dashed border-[#D2D2CF]">
          <ImageCover coverImage={getImage} uploaded={formData.coverImageUrl} coverPath='coverImages' />
        </div>

        <div className="w-full flex flex-col gap-3">
          {/* <label htmlFor="title" className="font-16 font-medium text-[#2E2E27]">Enter pitchdeck name</label> */}
          <p className="font-16 font-medium text-[#2E2E27]"> Enter pitchdeck name </p>
          <input
            name="title"
            placeholder="Title..."
            value={formData.title}
            onChange={(e) => handleChange(e)}
            className="w-full px-4 py-2 border border-[#C1C9C8]"
          />
        </div>

        <div className="w-full flex flex-col gap-3">
          <p className="font-16 font-medium text-[#2E2E27]">Amount Raised</p>
          <input
            name="amountRaised"
            placeholder="Amount Raised"
            value={formData?.amountRaised}
            onChange={(e) => handleChange(e)}
            className="w-full px-4 py-2 border border-[#C1C9C8]"
          />
        </div>

        <div className="w-full flex flex-col gap-3">
          <p className="font-16 font-medium text-[#2E2E27]"> Enter pitch deck tag </p>
          <SelectTag tag={formData.tag} value={handleTags} />
        </div>

        <div className="w-full flex flex-col gap-3">
          <p className="font-16 font-medium text-[#2E2E27]"> About this pitchdeck </p>
          <TextArea
            showCount
            value={formData.about}
            onChange={handleBody}
            placeholder="Describe Pitch deck"
            style={{ height: 120, resize: 'none', padding: 8, border: '#C1C9C8' }}
            className="w-full h-[121px] px-4 py-6 border border-[#C1C9C8]"
          />
        </div>

        <UploadPdf pdfUploaded={handlePdf} />
        <ImageUpload contentImages={images} contentPath='contentImages' updatedImagesUrl={uploadedImage} title="Pitch images" />

      </div>
    </div>
  )
}

export default EditPitch;
