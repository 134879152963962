import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { PaypalScriptProvider } from "@paypal/react-paypal-js";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase-config";
import {QueryClient, QueryClientProvider} from 'react-query'
import Login from "./auth/Login";
import Navbar from "./sections/Navbar";
import Blog from "./pages/Blog";
import SinglePost from "./pages/SinglePost";
import { ScrollToTop } from "./component/ScrollToTop";
import Pitchdeck from "./pages/Pitchdeck";
import Templates from "./pages/Templates";
import { store } from "./store";
import SinglePitch from "./pages/SinglePitch";
import Layout from "./sections/Layout";
import UploadBlog from "./upload/uploadBlog";
import EditBlog from "./upload/EditBlog";
import SendMail from "./send/SendMails";
import EditPitch from "./upload/EditPitch";
import SingleTemplate from "./pages/SingleTemplate";
import UploadPitch from "./upload/UploadPitch";
import EditTemplate from "./upload/EditTemplate";
import UploadTemplate from "./upload/UploadTemplate";
import Dashboard from "./pages/Dashboard";
import { Users } from "./pages/Users";
import { Terms } from "./pages/Terms";
import { Revenue } from "./pages/Revenue";
import { Policy } from "./pages/Policy";
import { ActiveUsers } from "./pages/ActiveUsers";

export const accessToken = (tokens)=>{
  const token = tokens
  // console.log(token)
return token
}
function App() {
  const [user, error] = useAuthState(auth);

  const queryClient = new QueryClient()


  const {setToken} = store()

  useEffect(()=>{
      accessToken(user?.accessToken)
      setToken(user?.accessToken)
      // fetchData(user?.accessToken)
  },[setToken, user])
  


  return (
      <div className="App">
    <QueryClientProvider client={queryClient}>

        <Router>
          <ScrollToTop />

          
           {user ?
              <Layout>
          <Routes>
            <Route path="*" element={<Dashboard />} />
            <Route path="/blogs" element={<Blog />} />
            <Route path="/users" element={<Users/>} />
            <Route path="/blog/:post" element={<SinglePost />} />
            <Route path="/editblog/:post" element={<EditBlog />} />
            <Route path="/uploadblog" element={<UploadBlog />} />
            <Route path="/pitchdecks" element={<Pitchdeck />} />
            <Route path="/pitch/:pitch" element={<SinglePitch />} />
            <Route path="/editpitch/:pitch" element={<EditPitch />} />
            <Route path="/uploadpitch" element={<UploadPitch />} />
            <Route path="/templates" element={<Templates />} />
            <Route path="/template/:template" element={<SingleTemplate />} />
            <Route path="/edittemplate/:template" element={<EditTemplate />} />
            <Route path="/uploadtemplate" element={<UploadTemplate />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/revenue" element={<Revenue />} />
            <Route path="/policy" element={<Policy />} />
            <Route path="/send-mail" element={<SendMail /> } />
            <Route path="/active-users" element={<ActiveUsers /> } />
          </Routes>
           </Layout>
           : (!user) &&
           <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Login />} />
            </Routes>}
        </Router>
        {/* {console.log(user)} */}
        </QueryClientProvider>
      </div>
  );
}

export default App;
