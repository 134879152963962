import React from 'react'
import { Link } from 'react-router-dom'
import Arrow from "./../assets/arrowRight.svg"
import { useParams } from 'react-router-dom';
import { deleteDoc, doc } from 'firebase/firestore';
import { Modal } from "antd";
import { db } from '../firebase-config';
import DeleteButton from '../assets/delete.png'


const BlogCard = ({ image, date, id, desc, deleteSinglePost }) => {

  let params = useParams();
  const { confirm } = Modal;

  const deletePost = async (id) => {
    confirm({
      title: "Delete post ",
      content: "Delete blog post?",
      okText: 'Yes',  
      okType: 'danger',
      async onOk() {
          const postDoc = doc(db, "posts", id);
          await deleteDoc(postDoc);
          deleteSinglePost("refreshed")
      },
      onCancel() { },
    });
    
  };
//  Admin blog card

  return (
    <div className='relative h-fit tablet:h-full tablet:pb-[80px] laptop:pb-0'>
      <img src={image} className='mb-6 w-full h-full max-h-[342px]' alt="" />
      {!params.id && <p className='text-[#64645F] text-[16px] leading-6'>{date}</p>}
        <Link to={{
        pathname: `/blog/${id}`,
        state: { id: id }
      }} >
        <p className='my-2 text-[#2E2E27] text-[16px] font-[600] leading-[22px] laptop:text-[20px] laptop:font-bold laptop:leading-[28px] tracking-[-0.8px]' dangerouslySetInnerHTML={{ __html: (desc.length > 70 ?
        `${desc.substring(0, 70)} ...` : desc) }}></p>
     <div className='flex w-fit items-center justify-between w-full'>
        <div className='flex w-fit items-center'>
            <p className='text-[#21AB68] text-[16px] font-medium leading-[22px]'>Read more</p>
        <img src={Arrow} alt="" />
        </div>
        <Link to={{
        pathname: `/editblog/${id}`,
        state: { id: id }
      }} ><button  className="border border-[#2E2E2F] bg-[#EEFCF5] rounded-md p-4 text-sm text-[#2E2E2F] font-bold hover:bg-slate-300" > Update Post </button>
        
        </Link>
        </div>
       </Link>
       <div className="absolute top-2 right-1 z-10">
                    <button
                      onClick={() => {
                        deletePost(id);
                      }}
                      className="rounded-[50%] border bg-white border-white p-2 flex justify-center items-center"
                    >
                     <img src={DeleteButton} alt="" className='w-6' />
                     {/* <p>X</p> */}
                    </button>
                </div>
    </div>
  )
}

export default BlogCard