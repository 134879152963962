// import { useMediaQuery } from "react-responsive"
import SideBar from "./SideBar"
// import { useState } from "react"
import Navbar from "./Navbar"

const Layout = ({children}) => {
  // const [open, setOpen] = useState(false)

  // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' })
  // const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' })


// console.log(email)

  // const openSidebar = () =>{
  //   open === false ? setOpen(true) : setOpen(false)
  //   console.log("Clicked")
  // }
  return (
<div className="">
  <div className="">
     {/* <SideBar/> */}
     <Navbar/>
<div className="mt-[60px]">
{children}
</div>
  </div>
    </div>  )
}

export default Layout