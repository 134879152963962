
import { store } from "../store"
import { Oval } from "react-loader-spinner"



const Loading = () => {

  const { componentLoading } = store()


  return (
    componentLoading ?
      // <div className="flex flex-col justify-center items-center bg-modalBlur absolute min-h-[100px] top-0 left-0 z-[350] w-full h-full">
        <Oval
          height={16}
          width={16}
          color="#FFFFFF"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="#4F7D54"
          strokeWidth={5}
          strokeWidthSecondary={5}
        />
      // </div>
      : undefined
  )
}

export default Loading