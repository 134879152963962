import Cards from "../component/Dashboard/Cards";
import Charts from "../component/Dashboard/Charts";
import Hero from "../component/Dashboard/Hero";


const Dashboard = () => {


  return (
    <div className="w-full">
      <div className="bg-[#FFF]">
        <div className="w-full laptop:max-w-[1152px] mx-auto px-4 tablet:px-6 laptop:px-8 xl:px-0 my-[40px] tablet:my-[80px] laptop:my-[100px] flex flex-col gap-6 tablet:gap-10 laptop:gap-14 desktop:gap-24">
    <Hero />
    <Cards />
    <Charts />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
