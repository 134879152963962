

import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import axios from '../../lib/axios';

const SelectTag = ({tag, value}) => {
  const [options, setOptions] = useState([])
  const [tags, setTags] = useState([])


  const getTags =  async () => {
    try {
       await axios.get(`/tag/allTags`).then(function (response) {
        setTags(response.data.tags);
      });
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  useEffect(()=>{
    getTags()
  },[])

  const handleChange = (selected) => {
    value(selected)
  };

  useEffect(()=>{
    const options = [];
    for (let i = 0; i < tags.length; i++) {
      options.push({
        value: tags[i]?.name,
        label: tags[i]?.name,
      });
      setOptions(options)
    }
  },[ tags])
  
  return(
    <Select
    // mode="multiple"
      defaultValue={tag}
      placeholder="Select tag"
      onChange={handleChange}
      options={options}
    />
  );
  }
export default SelectTag;






