import { useEffect, useState } from "react";
import { store } from "../../store";
import PitchCard from "./PitchCard";
import axios from "../../lib/axios";
import EmptyPitch from "../EmptyPitch";

const DynamicListComponent = () => {
  const { pitches, fetchPitches, setIsLoading } = store();
  const [array, setArray] = useState<any[]>([]);


  useEffect(() => {
    const shuffleArray = async () => {
      // Create a copy of the array to avoid modifying the original array
      if (pitches) {
        const shuffledArray = [...pitches];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [shuffledArray[i], shuffledArray[j]] = [
            shuffledArray[j],
            shuffledArray[i],
          ];
        }
        setArray(shuffledArray);
      }
    };

    shuffleArray();
  }, [pitches]);


  const deletePost =()=>{
    // Reload after deleted successfully
    try {
      axios.get('/pitch/getAll')
        .then(function (response) {
          fetchPitches(response.data.pitchDecks)
          setIsLoading(false);
        })
  
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  }

  
  return pitches?.length === 0 ? (
    <EmptyPitch />
  ) : (
    <>
      {array?.map((item: any[], index: number) => (
        <div key={index}>
              <PitchCard key={index} pitch={item} deleteSinglePost={deletePost} />
        </div>
      ))}
    </>
  );
};

export default DynamicListComponent;
