import { useState } from 'react'
import { useAuthState } from "react-firebase-hooks/auth";
import {auth, logout} from "../firebase-config";

import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import Logo from "./../assets/Logo.svg"
import Ham from "./../assets/hambugger.svg"

const Navbar = () => {
    const [visibility, setVisibility] = useState(false)
    const isSmallScreen = useMediaQuery({ query: '(max-width: 1023px)' })
    const [user, error] = useAuthState(auth);


const toggleNavbar = ()=>{
 visibility ? setVisibility(false) : setVisibility(true)
}

    return (
        <header className=" w-full bg-white-1 px-4 tablet:px-6 laptop:px-8 xl:px-0 fixed top-0 z-20">
            <nav className="sticky top-0 z-10  block items-center  h-[40px] my-[10px]">
                <div
                    className="flex flex-wrap place-self-center items-center justify-between  w-full laptop:max-w-[1152px] mx-auto">
                    <Link to='/' className="flex items-center" onClick={toggleNavbar}>
                        <img src={Logo} className="w-[149px] h-[37px] mr-3"
                            alt="Pitchdeck Design Logo" />
                    </Link>
                    <div className="flex items-center laptop:order-1">
                        {
                            isSmallScreen ? <>
                            <img src={Ham} className="w-6" alt="Hambugger Icon" onClick={toggleNavbar} />
                            {visibility && 
                            <div className='fixed top-[60px] left-0 h-full bg-white-1 w-full py-4 px-6 tablet:px-8'>
                                <ul className="flex flex-col font-medium gap-8">
                                    <li className="" onClick={toggleNavbar}> <Link to='/' className="text-[#0A0A0A] text-sm leading-5 font-medium">Statistics</Link> </li>
                                <li className="" onClick={toggleNavbar}> <Link to='/blogs' className="text-[#0A0A0A] text-sm leading-5 font-medium">Blogs</Link> </li>
                                <li className="" onClick={toggleNavbar}> <Link to='/pitchdecks' className="text-[#0A0A0A] text-sm leading-5 font-medium">Pitchdecks</Link> </li>
                                    <li className="" onClick={toggleNavbar}> <Link to='/templates' className="text-[#0A0A0A] text-sm leading-5 font-medium">Templates</Link> </li>
                                    <li className="" onClick={toggleNavbar}> <Link to='/send-mail' className="text-[#0A0A0A] text-sm leading-5 font-medium">Send Mail</Link> </li>
                                  
                                                {/* <li className="" onClick={toggleNavbar}>
                                                <Link to="/uploadpitch"
                                                    className="bg-[#21AB68] border-[#21AB68] shadow-navbarLink inline-flex items-center justify-center p-2 text-[#ffffff]  text-sm leading-5 font-normal focus:outline-none ">
                                                    Upload Pitchdeck</Link> </li>
                                                    <li className="" onClick={toggleNavbar}>
                                                <Link to="/uploadtemplate"
                                                    className="bg-[#21AB68] border-[#21AB68] shadow-navbarLink inline-flex items-center justify-center p-2 text-[#ffffff]  text-sm leading-5 font-normal focus:outline-none ">
                                                    Upload Template</Link> </li>
                                                    <li className="" onClick={toggleNavbar}>
                                                <Link to="/uploadblog"
                                                    className="bg-[#21AB68] border-[#21AB68] shadow-navbarLink inline-flex items-center justify-center p-2 text-[#ffffff]  text-sm leading-5 font-normal focus:outline-none ">
                                                    Create Post</Link> </li> */}
                                                    <p onClick={() => logout()}
                                                    className="bg-red text-[#ffffff] border-redtext shadow-navbarLink inline-flex items-center justify-center p-2 text-sm leading-5 font-normal focus:outline-none w-fit">
                                                    Logout</p>  
                                                   
                                            {/* </li> */}

                                </ul>
                                </div>}
                            </>
                                :
                                <ul className="flex font-medium flex-row items-center gap-4 desktop:gap-8">
                                   <li className="">  <Link to='/' className="text-[#0A0A0A] text-sm leading-5 font-medium">Statistics</Link> </li>
                                   <li className="">  <Link to='/blogs' className="text-[#0A0A0A] text-sm leading-5 font-medium">Blogs</Link> </li>
                                   <li className="">  <Link to='/pitchdecks' className="text-[#0A0A0A] text-sm leading-5 font-medium">Pitchdecks</Link> </li>
                                   <li className="">  <Link to='/templates' className="text-[#0A0A0A] text-sm leading-5 font-medium">Templates</Link> </li>
                                   <li className="">  <Link to='/send-mail' className="text-[#0A0A0A] text-sm leading-5 font-medium">Send Mail</Link> </li>
                                   {/* <li className="">  <Link to='/uploadblog' className="bg-[#21AB68] border-[#21AB68] shadow-navbarLink inline-flex items-center justify-center p-2  text-[#ffffff]  text-sm leading-5 font-normal focus:outline-none ">Add Blog</Link> </li>
                                   <li className="">  <Link to='/uploadpitch' className="bg-[#21AB68] border-[#21AB68] shadow-navbarLink inline-flex items-center justify-center p-2  text-[#ffffff]  text-sm leading-5 font-normal focus:outline-none ">Add Pitch</Link> </li>
                                   <li className="">  <Link to='/uploadtemplate' className="bg-[#21AB68] border-[#21AB68] shadow-navbarLink inline-flex items-center justify-center p-2  text-[#ffffff]  text-sm leading-5 font-normal focus:outline-none ">Add Templates</Link> </li> */}

                                   {/* <>
                                   <Link smooth to="/uploadpitch"
                                            className="bg-[#21AB68] border-[#21AB68] shadow-navbarLink inline-flex items-center justify-center p-2  text-[#ffffff]  text-sm leading-5 font-normal focus:outline-none ">
                                             Upload Pitchdeck</Link> 
                                             <Link smooth to="/uploadtemplate"
                                            className="bg-[#21AB68] border-[#21AB68] shadow-navbarLink inline-flex items-center justify-center p-2  text-[#ffffff]  text-sm leading-5 font-normal focus:outline-none ">
                                             Upload Template</Link> 
                                        <Link smooth to="/uploadblog"
                                            className="bg-[#21AB68] border-[#21AB68] shadow-navbarLink inline-flex items-center justify-center p-2  text-[#ffffff]  text-sm leading-5 font-normal focus:outline-none ">
                                             Create Post</Link> 
                                            
                                              </> */}
                                                <p onClick={() => logout()}
                                              className="bg-red text-[#ffffff] border-red-600 shadow-navbarLink inline-flex items-center justify-center p-2 text-sm leading-5 font-normal focus:outline-none ">
                                              Logout</p> 
                                    
                                    {/* </li>00 */}

                                </ul>}
                    </div>
                </div>
            </nav>
        </header>


    )
}

export default Navbar