import { useState } from "react";


import { Input } from "antd";
import ImageUpload from "../component/UploadImages";
import { store } from "../store";
import Deliverables from "../component/Form/Deliverables";
import axios from "../lib/axios";
import ImageCover from "../component/UploadCover";
import Loading from "../component/LoadingComponent";
import { Notification } from "../component/Notification";

function UploadTemplate({ isAuth }) {
  const { token, setIsComponentLoading } = store()
  const { TextArea } = Input;
  const [formData, setFormData] = useState({
    name: "",
    about: "",
    templateCoverImageUrl: "",
    templateImagesUrl: [],
    pageHighlights: "",
    nigeriaLinkToPurchase: "",
    foreignLinkToPurchase: "",
    deliverables: [],
    costInNaira: "",
    costInDollar: "",
    numberOfPages: null,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const getImage = (res) => {
    setFormData({ ...formData, templateCoverImageUrl: res });
  }

  const handlePublish = async () => {
    console.log(formData)
    setIsComponentLoading(true)
    try {
      await axios.post('/templates/create', formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Credentials': true
        },
      })
        .then(function (response) {
          setFormData({
            name: "",
            about: "",
            templateCoverImageUrl: "",
            templateImagesUrl: [],
            pageHighlights: "",
            nigeriaLinkToPurchase: "",
            foreignLinkToPurchase: "",
            deliverables: [],
            costInNaira: "",
            costInDollar: "",
            numberOfPages: null,
          })
          setIsComponentLoading(false)
          Notification("Template Uploaded Successfully")
        });
    } catch (error) {
      setIsComponentLoading(false)
      Notification("Error Uploading Template")
      console.error("Error fetching data:", error);
    }
    setIsComponentLoading(false)
  }

  const uploadedImage = (images) => {
    setFormData({ ...formData, templateImagesUrl: images })
  }

  const handleAbout = e => {
    setFormData({ ...formData, about: e.target.value });
    // setFormData({ ...formData, about: ref.current.value});
  }

  const handleHighlight = e => {
    setFormData({ ...formData, pageHighlights: e.target.value })
  }

  const deliverables = e => {
    setFormData({ ...formData, deliverables: e });
    console.log([e])
  }

  return (
    <div className="BlogPage bg-[#F2F1E8]">
      <div className="">
        <div className="w-full flex justify-between items-center laptop:max-w-[1152px] mx-auto px-4 tablet:px-6 laptop:px-8 xl:px-0 py-[40px] tablet:pt-[80px] laptop:pt-[100px]">
          <p className="text-[64px] font-bold leading-[72px] tracking-[-2px] text-[#2E2E27] mx-auto w-full">
            Upload Template</p>
          <p className="border border-[#21AB68] bg-[#21AB68] p-4 text-16 text-[#FFFFFF] hover:bg-[#21AB78] w-fit whitespace-nowrap  flex items-center gap-2 cursor-pointer" onClick={handlePublish}> Save and upload deck <Loading />
          </p>
        </div>
      </div>

      <div className="w-[90%] max-w-[559px] flex flex-col gap-4 tablet:gap-6 laptop:gap-8  mx-auto">
        <div className="w-full flex flex-col gap-4 bg-white h-[342px] items-center justify-center border border-dashed border-[#D2D2CF]">
          <ImageCover coverImage={getImage} coverPath='templateCoverImages' />
        </div>

        <div className="w-full flex flex-col gap-4">
          {/* <label htmlFor="title" className="font-16 font-medium text-[#2E2E27]">Enter pitchdeck name</label> */}
          <p className="font-16 font-medium text-[#2E2E27]"> Enter Template Name </p>
          <input
            name="name"
            placeholder="Title..."
            value={formData.name}
            onChange={(e) => handleChange(e)}
            className="w-full px-4 py-2 border border-[#C1C9C8]"
          />
        </div>

        <div className="w-full flex flex-col gap-4">
          <p className="font-16 font-medium text-[#2E2E27]"> About this template </p>
          <TextArea
            showCount
            // maxLength={100}
            onChange={handleAbout}
            placeholder="Describe Pitch deck"
            style={{ height: 120, resize: 'none', padding: 8, border: '#C1C9C8' }}
            className="w-full h-[121px] px-4 py-6 border border-[#C1C9C8]"
          />
        </div>

        <div className="w-full flex flex-col gap-4">
          <p className="font-16 font-medium text-[#2E2E27]">Template Highlights </p>
          <TextArea
            showCount
            // maxLength={100}
            onChange={handleHighlight}
            placeholder="Describe Pitch deck"
            style={{ height: 120, resize: 'none', padding: 8, border: '#C1C9C8' }}
            className="w-full h-[121px] px-4 py-6 border border-[#C1C9C8]"
          />
        </div>

        <div className="w-full flex flex-col gap-4">
          <p className="font-16 font-medium text-[#2E2E27]">Link to Purchase (Naira)</p>
          <input
            name="nigeriaLinkToPurchase"
            placeholder="www.pitchdeck.design/link"
            value={formData.nigeriaLinkToPurchase}
            onChange={(e) => handleChange(e)}
            className="w-full px-4 py-2 border border-[#C1C9C8]"
          />
        </div>

        <div className="w-full flex flex-col gap-4">
          <p className="font-16 font-medium text-[#2E2E27]">Template cost (Naira)</p>
          <input
            name="costInNaira"
            placeholder="Amount"
            value={formData.costInNaira}
            onChange={(e) => handleChange(e)}
            className="w-full px-4 py-2 border border-[#C1C9C8]"
          />
        </div>

        <div className="w-full flex flex-col gap-4">
          <p className="font-16 font-medium text-[#2E2E27]">Link to Purchase (Dollar)</p>
          <input
            name="foreignLinkToPurchase"
            placeholder="www.pitchdeck.design/link"
            value={formData.foreignLinkToPurchase}
            onChange={(e) => handleChange(e)}
            className="w-full px-4 py-2 border border-[#C1C9C8]"
          />
        </div>

        <div className="w-full flex flex-col gap-4">
          <p className="font-16 font-medium text-[#2E2E27]">Template cost (Dollar)</p>
          <input
            name="costInDollar"
            placeholder="Amount"
            value={formData.dollarAmount}
            onChange={(e) => handleChange(e)}
            className="w-full px-4 py-2 border border-[#C1C9C8]"
          />
        </div>

        <div className="w-full flex flex-col gap-4">
          <label className="font-16 font-medium text-[#2E2E27]">Number of pages</label>
          <input
            name="numberOfPages"
            placeholder="5"
            value={formData.numberOfPages}
            onChange={(e) => handleChange(e)}
            className="w-full px-4 py-2 border border-[#C1C9C8]"
          />
        </div>
        <Deliverables value={deliverables} />
        <ImageUpload contentImages={uploadedImage} contentPath='templateImages' title="Template images" />
      </div>
    </div>
  );
}

export default UploadTemplate;
