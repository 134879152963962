import React, { useState, useEffect } from "react";
import ReactQuill from 'react-quill';
import '../../node_modules/react-quill/dist/quill.snow.css'
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, db, auth } from "../firebase-config";
import { Upload, Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";



function EditBlog({ isAuth }) {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [post, setPost] = useState({});

  const [title, setTitle] = useState('')
  const [keywords, setKeywords] = useState('')
  const [postText, setPostText] = useState('')
  const [author, setAuthor] = useState('')
  const [image, setImage] = useState('')
  const [date, setDate] = useState('')
  const [uploaded, setUpload] = useState(false)


  let navigate = useNavigate();
  let params = useParams();

  const getCollectionRef = doc(db, "posts", params.post);
 console.log(params.post)


  useEffect(() => {
    const getPost = async () => {
      const data = await getDoc(getCollectionRef);
      setPost(data.data());
      let post = data.data()
console.log(data.data().image)

setTitle(post.title)
setTitle(post.keywords)
setPostText(post.postText)
setAuthor(post.author)
setImage(data.data().image)
setDate(post.date)
    };
console.log(post.image)
    getPost();
  }, []);


  const handleImageChange = (e) => {
    setImage(e.file)
    setUpload(true)
    console.log(e.file)
  };


  const handlePublish = () => {

    console.log(title, image, postText, date, author, keywords)
    console.log(post)
    setLoading(true)
    const storageRef = ref(
      storage,
      `/images/${Date.now()}${image}`
    );

    const uploadImage = uploadBytesResumable(storageRef, image);

    uploadImage.on(
      "state_changed",
      (snapshot) => {
        const progressPercent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progressPercent);
      },
      (err) => {
        console.log(err);
      },
      () => {
        const current = new Date();
        const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

        getDownloadURL(uploadImage.snapshot.ref).then((url) => {

          uploaded ?  console.log(url, "uploaded:", uploaded) : console.log(image,  "uploaded:", uploaded)

          const postsCollectionRef = doc(db, "posts", params.post);
          updateDoc(postsCollectionRef, {
            title: title,
            keywords: keywords,
            postText: postText,
            image: uploaded ? url : image,
            author: { name: auth.currentUser.displayName, id: auth.currentUser.uid },
            date: date,
          })
            .then(() => {
              navigate("/blog");
              setTitle("")
              setPostText("")
              setImage("")
              setKeywords("")
            })
            .catch((err) => {
              console.log(err)
            });
        });
      }
    );
  };

  const handleBody = e => {
    setPostText(e)
  }

  return (
    <div className="BlogPage">

      <div className="bg-[#EEFCF5]">
        <div className="w-full laptop:max-w-[1152px] mx-auto px-4 tablet:px-6 laptop:px-8 xl:px-0 py-[40px] tablet:py-[80px] laptop:py-[100px]">
          <p className="text-[64px] font-bold leading-[72px] tracking-[-2px] text-[#2E2E27] mx-auto w-fit">
            Update Blog Post
          </p>
        </div>


      </div>

      <div className="w-full laptop:max-w-[1152px] mx-auto px-4 tablet:px-6 laptop:px-8 xl:px-0 py-[40px] tablet:py-[80px] laptop:py-[100px]">



        <div className="w-full mb-4 tablet:mb-6 laptop:mb-8">
          <input
            name="title"
            placeholder="Title..."
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full px-4 py-2 border border-[#6e6e74] rounded-md"
          />
        </div>


        <div className="w-full mb-4 tablet:mb-6 laptop:mb-8">
          <input
          name="keywords"
            placeholder="Enter keywords here"
            value={keywords}
              onChange={(e) => setKeywords(e.target.value)}
              className="w-full px-4 py-2 border border-[#6e6e74] rounded-md"
          />
      </div>
        <div className="w-full mb-4 tablet:mb-6 laptop:mb-8">

          <Upload onChange={handleImageChange}
            maxCount={1}
            listType="picture"
            beforeUpload={() => false}
          >
            <Button>Upload picture</Button>
          </Upload>
        </div>

        <ReactQuill
          placeholder="Write a blog post........"
          modules={EditBlog.modules}
          formats={EditBlog.formats}
          onChange={handleBody}
          value={postText} 
        /> 
        <button className="border border-[#2E2E2F] bg-[#EEFCF5] rounded-md p-2 px-4 text-lg text-[#2E2E2F] font-bold hover:bg-slate-300" style={{ marginTop: "40px" }} onClick={handlePublish}> Submit Post
        </button>

      </div>
    </div>
  );
}

EditBlog.modules = {
  toolbar: [
    [{ 'font': [] }],
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'header': 1 }, { 'header': 2 }, { header: [3, 4, 5, 6] }],              // custom button values
    ['blockquote', 'code-block', 'code'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'align': [] }],
    [{ 'direction': 'ltr' }],                         // text direction
    ['link'],
    ['image'], ['video']
    ['clean'],                                       // remove formatting button


  ]
}

EditBlog.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "video",
  "code-block",
  'indent',
  "color",
  'direction',
  'clean',
  'background',
  'script',
  'code',
  'align'
];

export default EditBlog;
